import { defineStore } from "pinia";
import { useRouter } from "vue-router";
import { useStorage } from '@vueuse/core'


import { UsersStater } from "@/interfaces/users";
const devProfile = {
    "platform": "iOS",
    "osVersion": "12.2",
    "deviceMaker": "Apple",
    "deviceModel": "iPhone X 128GB"
}

const initialToken = {
    // accessTokenId: '060-pri-20230101-132518-435-73b945497c65',
    accessTokenId: '060-pri-20221001-173137-622-33b44545cc82',
    userId: '100-20210925-173147-884-bf6ddcdda552'
}

function getReminderByPrescriptionId(reminders: [any], prescriptionId: string) {
    const found = reminders.find(o => o.prescriptionId === prescriptionId);
    if (found) {
        return found;
    }
}

export const useUsers = defineStore("users", {
    state: (): UsersStater => ({
        avatar: useStorage('avatar', ''),
        userId: useStorage('userId', ''),
        userIdentityId: '',
        accountId: useStorage('accountId', ''),
        email: useStorage('email', ''),
        fullName: useStorage('fullName', ''),
        firstName: useStorage('firstName', ''),
        lastName: useStorage('lastName', ''),
        dateOfBirth: '',
        profileIds: [0],
        profileId: '',
        devProfile,
        token: useStorage('token', ''),
        conditions: [{}],
        appointments: [{}],
        prescriptions: [{}],
        reminders: [{}],
        patientData: {},
        patientInfoId: '',
        ageYears: '',
        lifestyle: {
            diet: [''],
            sleep: [''],
            excercise: [''],
            travel: [''],
            other: [''],
        },
        welnessQuestions: {
            general:  [''],
            generalID: '',
            covid: [''],
            covidID: '',
            cancer: [''],
            cancerID: '',
            heart: [''],
            heartID: ''
        }
    }),

    actions: {
        setAvatar(b64: any) {
            this.avatar = b64
        },
        setFullName(name: string) {
            this.fullName = name;
        },
        setFirstName(name: string) {
            this.firstName = name;
        },
        setLastName(name: string) {
            this.lastName = name;
        },
        setEmail(name: string) {
            this.email = name;
        },
        setToken(token: string) {
            this.token = token;
        },
        setUserId(userId: string) {
            this.userId = userId;
        },
        setAccountId(accountId: string) {
            this.accountId = accountId;
        },
        setUserIdentityId(userIdentityId: string) {
            this.userIdentityId = userIdentityId;
        },
        setTheConditions(cons: any) {
            this.conditions = cons;
        },
        setAppointments(apps: any) {
            this.appointments = apps;
        },
        setPrescriptions(pres: any) {
            this.prescriptions = pres;
            console.log('setting prescriptions with reminder data');
            this.prescriptions.forEach(pre => {
                console.log('trying to get reminder of', pre.prescriptionId);
                pre.reminder = getReminderByPrescriptionId(this.reminders, pre.prescriptionId);
            });
        },
        setReminders(rem: any) {
            this.reminders = rem;
        },
        setPatientData(data: any) {
            this.patientData = data;
        },
        setPatientInfoId(infoId: string) {
            this.patientInfoId = infoId;
        },
        setAgeYears(age: string) {
            this.ageYears = age;
        },
        setLifeStyleDiet(arr: any) {
            this.lifestyle.diet = arr;
        },
        setLifeStyleSleep(arr: any) {
            this.lifestyle.sleep = arr;
        },
        setLifeStyleExcercise(arr: any) {
            this.lifestyle.excercise = arr;
        },
        setLifeStyleTravel(arr: any) {
            this.lifestyle.travel = arr;
        },
        setLifeStyleOther(arr: any) {
            this.lifestyle.other = arr;
        },
        setProfileId(id: string) {
            this.profileId = id;
        },
        setWelnessQuestionID(id: string, cat: string) {
            switch (cat) {
                case 'general':
                    this.welnessQuestions.generalID = id;
                    break;
                case 'covid':
                    this.welnessQuestions.covidID = id;
                    break;
                case 'cancer':
                    this.welnessQuestions.cancerID = id;
                    break;
                case 'heart':
                    this.welnessQuestions.heartID = id;
                    break;

                default:
                    break;
            }
        },
        setWellnessQuestions(arr: any, cat: string) {
            switch (cat) {
                case 'general':
                    this.welnessQuestions.general = arr;
                    break;
                case 'covid':
                    this.welnessQuestions.covid = arr;
                    break;
                case 'cancer':
                    this.welnessQuestions.cancer = arr;
                    break;
                case 'heart':
                    this.welnessQuestions.heart = arr;
                    break;

                default:
                    break;
            }
        },
    },

    getters: {
        getAvatar(state): string {
            return state.avatar
        },
        getName(state): string {
            return state.fullName;
        },
        getFirstName(state): string {
            return state.firstName;
        },
        getLastName(state): string {
            return state.lastName;
        },
        getEmail(state): string {
            return state.email;
        },
        getDevProfile(state): any {
            return state.devProfile;
        },
        getUserId(state): string {
            return state.userId;
        },
        getUserIdentityId(state): string {
            return state.userIdentityId;
        },
        getAccountId(state): string {
            return state.accountId;
        },
        getConditions(state): [any] {
            return state.conditions;
        },
        getAppointments(state): [any] {
            return state.appointments;
        },
        getReminders(state): [any] {
            return state.reminders;
        },
        getPrescriptions(state): [any] {
            return state.prescriptions;
        },
        getConditionsNameVal(state): any {
            const arr: any = [];
            state.conditions.forEach(element => {
                console.log(element);
                if (element) {
                    const dat = {
                        label: element.conditionName,
                        value: element.conditionName
                    }
                    arr.push(dat);
                }
            });
            console.log(arr);
            return arr;
        },
        getPatientData(state): any {
            return state.patientData;
        },
        getPatientInfoId(state): string {
            return state.patientInfoId;
        },
        getAgeYears(state): string {
            return state.ageYears;
        },
        getProfileId(state): string {
            return state.profileId;
        },
        getLifeStyleDiet(state): Array<string> {
            return state.lifestyle.diet;
        },
        getLifeStyleSleep(state): Array<string> {
            return state.lifestyle.sleep;
        },
        getLifeStyleExcercise(state): Array<string> {
            return state.lifestyle.excercise;
        },
        getLifeStyleTravel(state): Array<string> {
            return state.lifestyle.travel;
        },
        getLifeStyleOther(state): Array<string> {
            return state.lifestyle.other;
        },
        getWellnessCancerQuestions(state): Array<string> {
            return state.welnessQuestions.cancer;
        },
        getWellnessGeneralQuestions(state): Array<string> {
            return state.welnessQuestions.general;
        },
        getWellnessHeartQuestions(state): Array<string> {
            return state.welnessQuestions.heart;
        },
        getWellnessCovidQuestions(state): Array<string> {
            return state.welnessQuestions.covid;
        },
        getWellnessCancerQuestionsID(state): string {
            return state.welnessQuestions.cancerID;
        },
        getWellnessGeneralQuestionsID(state):  string {
            return state.welnessQuestions.generalID;
        },
        getWellnessHeartQuestionsID(state):  string {
            return state.welnessQuestions.heartID;
        },
        getWellnessCovidQuestionsID(state):  string {
            return state.welnessQuestions.covidID;
        },
        getTokenIfExists(state): string {
            if (state.token !== '') {
                return state.token;
            } else {
                return `{'accessTokenId':'${initialToken.accessTokenId}', 'userId':'${initialToken.userId}'}`
            }
        }
    }
})