<script setup>
import {
  IonModal,
  IonContent,
  IonRow,
  IonCol,
  IonGrid,
  IonButton,
  IonText,
  IonLabel,
  IonSpinner,
  toastController,
} from "@ionic/vue";
import RegisterInput from "@/components/RegisterInput.vue";
import SelectInput from "@/components/SelectInput.vue";
import { defineProps, defineEmits, ref, computed, nextTick } from "vue";
import welnessAPI from "@/services/welnessAPI";

const props = defineProps([
  "isOpen",
  "patientQuestionSetId",
  "name",
  "description",
  "recurrence",
  "schedules",
]);
const emit = defineEmits(["update:isOpen", "done"]);

const form = ref({
  name: "",
  description: "",
  schedules: "10:00 AM,03:00 PM",
  days: ["1", "2", "3", "4", "5", "6", "7"],
  event: "",
});

function mappingProps() {
  form.value.name = props.name || "";
  form.value.description = props.description || "";
  form.value.event = props.recurrence
    ? props.recurrence.length > 1
      ? "W"
      : props.recurrence
    : "";
  form.value.schedules = props.schedules.join(",");
  if (props.recurrence && props.recurrence.length > 1) {
    form.value.days = props.recurrence.split("W-")[1].split("-");
  }
}

const recurrence = computed(() => {
  return form.value.event === "*" ? "*" : "W-" + form.value.days.join("-");
});

const events = [
  { name: "As needed", value: "*" },
  { name: "Weekly", value: "W" },
];

const times = [
  { name: "1 time (10:00 AM)", value: "10:00 AM" },
  { name: "2 times (10:00 AM, 03:00 PM)", value: "10:00 AM,03:00 PM" },
  {
    name: "3 times (10:00 AM, 03:00 PM, 08:00 PM)",
    value: "10:00 AM,03:00 PM,08:00 PM",
  },
];

const days = ref([
  { name: "Sunday", value: "1" },
  { name: "Monday", value: "2" },
  { name: "Tuesday", value: "3" },
  { name: "Wednesday", value: "4" },
  { name: "Thursday", value: "5" },
  { name: "Friday", value: "6" },
  { name: "Saturday", value: "7" },
]);

const isLoading = ref(false);
const startValidate = ref(false);
async function submit() {
  startValidate.value = true;
  await nextTick();
  if (document.querySelector(".error")) return;

  try {
    isLoading.value = true;
    const { data } = await welnessAPI.setSchedule(props.patientQuestionSetId, {
      name: form.value.name,
      description: form.value.description,
      recurrence: recurrence.value,
      schedules: form.value.schedules.split(","),
    });
    if (data.id) {
      emit("done");
      emit("update:isOpen", false);
    }
    console.log(data);
  } catch (e) {
    const toast = await toastController.create({
      message: "Something went wrong.",
      duration: 3000,
      position: "top",
    });
    await toast.present();
  } finally {
    isLoading.value = false;
  }
}
</script>

<template>
  <ion-modal
    @willPresent="mappingProps"
    @didDismiss="$emit('update:isOpen', false)"
    :is-open="isOpen"
  >
    <ion-content class="ion-padding">
      <div class="modal-container">
        <div class="header">
          <h1>Setup schedule</h1>
        </div>
        <div class="body">
          <div class="inputContainer">
            <div class="subContainer">
              <ion-grid class="ion-no-padding">
                <ion-row>
                  <ion-col>
                    <RegisterInput
                      label="Name"
                      placeholderText=""
                      v-model="form.name"
                    />

                    <ion-text
                      v-if="startValidate && !form.name"
                      class="error"
                      color="danger"
                    >
                      this field is required
                    </ion-text>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </div>
            <div class="subContainer">
              <ion-grid class="ion-no-padding">
                <ion-row
                  ><ion-col>
                    <SelectInput
                      label="Recurrence"
                      placeholder="Choose..."
                      :datalist="events"
                      v-model="form.event"
                    />
                    <ion-text
                      v-if="startValidate && !form.event"
                      class="error"
                      color="danger"
                    >
                      this field is required
                    </ion-text>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </div>
            <div class="subContainer">
              <ion-grid class="ion-no-padding">
                <ion-row>
                  <ion-col v-if="form.event === 'W'">
                    <ion-label> Days </ion-label>
                    <div class="checkboxes-container">
                      <label
                        v-for="(item, i) in days"
                        :key="item.name"
                        class="checkbox"
                      >
                        <input
                          type="checkbox"
                          :id="`checkbox-${i}`"
                          :value="item.value"
                          v-model="form.days"
                        />
                        <span class="indicator"></span>
                        {{ item.name }}
                      </label>
                    </div>
                    <ion-text
                      v-if="startValidate && !form.days.length"
                      class="error"
                      color="danger"
                    >
                      this field is required
                    </ion-text>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </div>
            <div v-if="form.event === 'W'" class="subContainer">
              <ion-grid class="ion-no-padding">
                <ion-row
                  ><ion-col>
                    <SelectInput
                      label="Schedule"
                      placeholder="Choose..."
                      :datalist="times"
                      v-model="form.schedules"
                    />
                  </ion-col>
                </ion-row>
              </ion-grid>
            </div>
          </div>
        </div>
        <div class="footer">
          <ion-button @click="$emit('update:isOpen', false)">Cancel</ion-button>
          <ion-button @click="submit">
            <ion-spinner v-if="isLoading"></ion-spinner>
            <span v-else>Submit</span>
          </ion-button>
        </div>
      </div>
    </ion-content>
  </ion-modal>
</template>

<style lang="scss" scoped>
@import "./../styles/addCaregiverModal.scss";
.modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  .header {
    text-align: center;
  }
  .body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    ion-spinner {
      align-self: center;
      margin-top: 100px;
    }
  }
  .footer {
    flex-shrink: 0;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
}

.checkboxes-container {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  ion-item.date {
    width: 120px;
  }
}

.datetime-container {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

button.add-remove {
  padding: 8px 12px;
  background-color: gray;
  color: white;
  font-weight: 700;
  border-radius: 8px;
}

ion-modal {
  --min-height: 550px;
}

ion-row {
  gap: 20px;
}

ion-checkbox::part(container) {
  border-radius: 6px;
}

$blue: #3880ff;
$white: #ffffff;
$black: #2d3137;
$border-radius: 3px;

label.checkbox {
  cursor: pointer;
  margin-right: 1rem;
  padding-left: 1.75rem;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;

    &:focus ~ span {
      border: 2px solid lighten($black, 50%);
    }

    &:focus:checked ~ span {
      border: 2px solid darken($blue, 15%);
    }

    &:checked ~ span {
      color: $white;
      background: $blue
        url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+DQo8c3ZnIHdpZHRoPSIxMiIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgMTIgOSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgPHBhdGggZD0iTTQuNTc1IDguOTc3cy0uNDA0LS4wMDctLjUzNi0uMTY1TC4wNTcgNS42NGwuODI5LTEuMjI3TDQuNDcgNy4yNjggMTAuOTIxLjA4NmwuOTIzIDEuMTAzLTYuODYzIDcuNjRjLS4xMzQtLjAwMy0uNDA2LjE0OC0uNDA2LjE0OHoiIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPg0KPC9zdmc+)
        50% 40% no-repeat;
      border: 2px solid $blue;
    }
  }

  span {
    border-radius: $border-radius;
    position: absolute;
    left: 0;
    width: 18px;
    height: 18px;
    background-color: white;
    border: 2px solid lighten($black, 65%);
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
</style>
