import instance from "./API";

const getConfig = {
    data: {},
    headers: {
        'Content-Type': 'application/json'
    }
};

export default {
    makeNewQuestionSet(data: any) {
        return instance.post(`ai_engine/question_sets`, data);

    },

    getQuestionSets(userID: string) {
        return instance.get(`ai_engine/question_sets`, getConfig);
    },

    makeNewQuestion(data: any) {
        return instance.post(`ai_engine/questions`, data);
    },

    getNewPatient(userID: string, data: any) {
        return instance.get(`users/${userID}/personas/all`, getConfig);
    },

    getQuestionSet(patientID: string, name: string) {
        return instance.get(`ai_engine/patient_question_sets/${patientID}?PatientQuestionSet.name=${name}`)
    },

    setSchedule(patientQuestionSetId: string, data : any){
        return instance.put(`ai_engine/patient_question_sets/${patientQuestionSetId}`, data);
    },

    postAiPrompts(data: any){
        return instance.post(`ai/prompts?test_flag=true`, data);
    }

}