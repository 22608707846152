import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '../views/HomePage.vue'
import RegisterPage from '@/views/RegisterPage.vue';
import SigninPage from '@/views/SigninPage.vue';
import FirstPage from '@/views/FirstPage.vue';
import InvitationPage from '@/views/InvitationPage.vue';
import PatientsPage from '@/views/PatientsPage.vue';
import QuestionsPage from '@/views/QuestionsPage.vue';
import SchedulePage from '@/views/SchedulePage.vue';
import NetworkPage from '@/views/NetworkPage.vue';
import QuestionSetPage from '@/views/QuestionSetPage.vue';
import ForgotPasswordPage from '@/views/ForgotPasswordPage.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage,
    meta: { requiresAuth: false }
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterPage,
    meta: { requiresAuth: false }
  },
  {
    path: '/signin',
    name: 'Signin',
    component: SigninPage,
    meta: { requiresAuth: false }
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: ForgotPasswordPage,
    meta: { requiresAuth: false }
  },
  {
    path: '/first/',
    name: 'First',
    component: FirstPage,
    meta: { requiresAuth: true },
    children:[
      {
        path: '',
        name: 'index',
        redirect: '/first/patients'
      },
      {
        path: 'patients',
        name: 'patients',
        component: PatientsPage
      },
      {
        path: 'wellness',
        name: 'questionSet',
        component: QuestionSetPage
      },
      {
        path: 'network',
        name: 'network',
        component: NetworkPage
      }
      
    ]
  },
  {
    path: '/accept',
    name: 'Accept Invitation',
    component: InvitationPage,
    meta: { requiresAuth: false }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from) => {
  if (to.meta.requiresAuth && !localStorage.userId) {
    return {
      path: '/home',
    }
  }
  if (!to.meta.requiresAuth && localStorage.userId) {
    return {
      path: '/first'
    }
  }
})

export default router
