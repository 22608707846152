import axios, { AxiosResponse } from "axios";
import { useUsers } from "@/stores/users";

const url = 'https://platform.givatar.com/gplatform/v2/';
const authHeaderTokenVar = 'X-Access-Token';
const agentHeaderVar = 'X-User-Agent';
const acceptHeaderVar = 'Accept';

const instance = axios.create({
    baseURL: url
});

function storeNewTokenFromResponse(res: AxiosResponse) {
    const store = useUsers();
    const newToken = res.config.headers[authHeaderTokenVar];
    store.setToken(String(newToken));
}

instance.interceptors.request.use(
    (config) => {
        const store = useUsers();
        const token = store.getTokenIfExists;
        if (token) {
            config.headers[authHeaderTokenVar] = token;
            config.headers[agentHeaderVar] = 'givatar-web';
            config.headers[acceptHeaderVar] = 'application/json';
        } else {
            console.log("we have no token, so no call!");
        }
        return config;
    }
)

instance.interceptors.response.use(
    (res) => {
        storeNewTokenFromResponse(res);
        return res;
    },
    async (err) => {
        if (err.response) {
            if ([401, 404].includes(err.response.status))
                console.log('auth error');
        }
        return err.response;
    }
)

export default instance;