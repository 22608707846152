import instance from "./API";
import { SignupCheck, SignupData, SigninData } from '../interfaces/users';

const getConfig = {
    data: {},
    headers: {
        'Content-Type': 'application/json'
    }
};

export default {
    signUpCheck(data: SignupCheck) {
        return instance.post('users/sign_up/check?test_flag=true', data);
    },

    signUp(data: SignupData) {
        return instance.post('users/sign_up', data);
    },

    signIn(data: SigninData) {
        return instance.post('users/sign_in', data);
    },

    acceptInvitation(data: any, invitationId: any){
        return instance.post(`users/invitation/${invitationId}`, data)
    },

    getInvitation(invitationId: any){
        return instance.get(`users/invitation/${invitationId}`)
    },

    getAllPersonas(userID: string) {
        return instance.get(`users/${userID}/personas/all`, getConfig);
    },

    //fullname: firstName,lastName, dateOfBirth
    putUserInfo(userID: string, data: any) {
        return instance.put(`users/${userID}`, data);
    },

    //email, phone password
    putUserIdentity(userID: string, data: any) {
        return instance.put(`users/${userID}/user_identity`, data);
    },

    getUserInfo(userID: string) {
        return instance.get(`users/${userID}`, getConfig);
    },

    postNewPatient(userID: string, data: any) {
        return instance.post(`users/${userID}/profiles`, data);
    },

    putPatient(userID: string, data: any) {
        return instance.put(`users/profiles/${userID}`, data);
    },

    makeNewPatient(userID: string, data: any) {
        return instance.post(`ai_engine/patient/info/${userID}/PERSONAL_INFO`, data);
    },

    editPatient(patientInfoID: string, data: any) {
        return instance.put(`ai_engine/patient/info/${patientInfoID}`, data);
    },

    getPatientInfo(userID: string) {
        return instance.get(`ai_engine/patient/info/${userID}/ALL_INFO`, getConfig);
    },
    
    getPatientInfoLifeStyle (userID: string) {
        return instance.get(`ai_engine/patient/info/${userID}/LIFESTYLE_INFO`, getConfig);
    },

    addCondition(userID: string, condition: string, data: any) {
        condition = encodeURIComponent(condition);
        return instance.post(`ai_engine/patient/conditions/${userID}/${condition}`, data);
    },

    getConditions(userID: string) {
        return instance.get(`ai_engine/patient/conditions/all/${userID}?num_of_entry=30`, getConfig);
    },

    delConditions(condID: string) {
        return instance.delete(`ai_engine/patient/conditions/${condID}`, getConfig);
    },

    getLifeDiet(userID: string) {
        return instance.get(`ai_engine/patient/diet/all/${userID}?num_of_entry=30`, getConfig);
    },

    updatePatientInfo(patientID: string, data: any) {
        return instance.put(`ai_engine/patient/info/${patientID}`, data);
    },

    addPrescription(userID: string, data: any) {
        return instance.post(`prescriptions/account/${userID}?test_flag=true`, data);
    },

    putPrescription(prescriptionID: string, data: any) {
        return instance.put(`prescriptions/${prescriptionID}?test_flag=true`, data);
    },

    addReminders(prescriptionID: string, data: any) {
        return instance.post(`reminders/${prescriptionID}?test_flag=true`, data);
    },

    putReminders(reminderID: string, data: any) {
        return instance.put(`reminders/${reminderID}?test_flag=true`, data);
    },

    addAppointment(accountID: string, data: any) {
        return instance.post(`appointments/${accountID}`, data);
    },

    getAppointments(accountID: string) {
        return instance.get(`appointments/all/${accountID}`, getConfig);
    },

    updateAppointment(appointmentID: string, data: any) {
        return instance.put(`appointments/${appointmentID}`, data);
    },

    delAppointment(appointmentID: string) {
        return instance.delete(`appointments/${appointmentID}`, getConfig);
    },

    getPrescriptions(accountID: string) {
        return instance.get(`prescriptions/account/${accountID}`, getConfig);
    },

    getPrescription(prescriptionID: string) {
        return instance.get(`prescriptions/${prescriptionID}`, getConfig);
    },

    getReminders(accountID: string) {
        return instance.get(`reminders/account/${accountID}`, getConfig);
    },

    getReminder(reminderID: string) {
        return instance.get(`reminders/${reminderID}`, getConfig);
    },

    delPrescription(prescriptionID: string) {
        return instance.delete(`prescriptions/${prescriptionID}`, getConfig);
    },

    careGiverInvite(accoundID: string, data : any) {
        return instance.post(`users/${accoundID}/invite`, data);
    },

    getAvatar(userId: string) {
        return instance.get(`users/${userId}/profile_image`, { responseType:"blob" })
    },

    uploadAvatar(userId: string, formData: FormData) {
        return instance.post(`users/${userId}/profile_image`, formData);
    },

    getMembers(accountID: string) {
        return instance.get(`users/${accountID}/account_members`)
    },

    deleteMember(accountID: string,userID: string) {
        return instance.delete(`users/${accountID}/${userID}`)
    },

    sendOtp(data:any){
        return instance.post(`users/sign_in/recovery`, data);
    },

    verifyOtp(data:any){
        return instance.post(`users/sign_in/recovery/verify`, data);
    }
}