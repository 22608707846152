<script setup>
import { defineProps, defineEmits, ref, watch } from "vue";
import { IonIcon, IonItem, IonLabel, IonInput } from "@ionic/vue";
import { pencil, search } from "ionicons/icons";

const props = defineProps({
  label: String,
  value: String,
  readOnly: Boolean,
  modelValue: String,
  type: String,
  placeholderText: String,
  multiline: {
    type: Boolean,
    default: false,
  },
  inputType: {
    type: String,
    default: "text",
  },
});
const emit = defineEmits(["update:modelValue"]);

const localValue = ref(props.modelValue);
watch(
  () => props.modelValue,
  (newVal) => {
    localValue.value = newVal || "";
  }
);

function onInputChange(event) {
  emit("update:modelValue", event.target.value);
}
</script>

<template>
  <ion-label>{{ props.label }}</ion-label>
  <ion-item lines="none">
    <ion-input
      v-if="!props.multiline"
      class="input-font"
      :type="inputType"
      :value="localValue"
      @input="onInputChange"
      :placeholder="props.placeholderText"
      :readOnly="props.readOnly"
    ></ion-input>
    <textarea
      v-else
      class="input-font"
      v-model="localValue"
      @input="onInputChange"
      :placeholder="props.placeholderText"
      :readOnly="props.readOnly"
    ></textarea>
    <ion-icon v-if="props.type == 'search'" :icon="search" />
    <ion-icon v-else :icon="pencil" />
  </ion-item>
</template>

<style lang="scss" scoped>
@import "./../styles/registerInput.scss";

/* Add appropriate styling for textarea */
textarea {
  width: 100%;
  border: none;
  outline: none;
  /* other styling as needed */
}
</style>
